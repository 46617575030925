module controllers {
    export module master {

        interface IRefundSettingCtrlScope extends ng.IScope {
        }

        interface IRefundSettingParams extends ng.ui.IStateParamsService {
        }

        export class refundSettingListCtrl{
            static $inject = ["$scope",
                "$rootScope",
                "generalService",
                "$q",
                "bsLoadingOverlayService",
                "entityService",
                "$timeout",
                '$uibModal',
                'refundSettingService',
                'countryService',
                '$state',
                '$stateParams',
                '$transitions',
                'statusService',
                'refundClaimTypeService'

            ];

            gridApi: uiGrid.IGridApi;
            allDirtyRows: Array<interfaces.master.IRefundSettingDisplay>;
            searchAccordian: boolean = false;
            myHook: any;

            refSettingSearch: interfaces.master.IRefundSettingSearch = {
                
            };

            paginationOptions = {
                pageNumber: 1,
                pageSize: 10
            };

            ctrlName: string;

            sortName: string;
            sortDirection: string;

            dropdownsObject = {
                RefundSettingStatusList: Array<interfaces.applicationcore.IDropdownModel>(),
            };

            constructor(private $scope: IRefundSettingCtrlScope,
                private $rootScope: interfaces.applicationcore.IRootScope,
                public generalService: interfaces.applicationcore.IGeneralService,
                private $q: ng.IQService, private bsLoadingOverlayService,
                private entityService: interfaces.applicationcore.IEntityService,
                private $timeout: ng.ITimeoutService,
                private $uibModal: ng.ui.bootstrap.IModalService,
                private refundSettingService: interfaces.master.IRefundSettingService,
                private countryService: interfaces.applicationcore.ICountryService,
                private $state: ng.ui.IStateService,
                private $stateParams: IRefundSettingParams,
                private $transitions: ng.ui.core.ITransition,
                private statusService: interfaces.master.IStatusService,
                private refundClaimTypeService: services.tariff.refundClaimTypeService
            ) {
                this.refSettingSearch = {
                    IsActive: true
                };

                this.ctrlName = 'Refund Setting List Controller';

                this.loadRefundSettings();
                //this.getRefundSettingStatusDropdown();

                this.myHook = $transitions.onSuccess({
                    to: 'auth.MasterData.RefundSetting',
                    from: 'auth.MasterData.RefundSetting.**'
                }, () => {
                    this.loadRefundSettings();
                });

                $scope.$on('$destroy', () => {
                    $transitions.onStart({}, this.myHook)
                });
            }

            loadRefundSettings() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'refundSetting'
                },
                    () => {
                        var searchObject: interfaces.applicationcore.ISearchObject = {
                            filters: [],
                            sorts: []
                        }

                        angular.forEach(this.refSettingSearch, (n, key) => {
                            if (n["Id"]) {
                                searchObject.filters.push({ Name: key, Value: n.Id });
                            } else {
                                searchObject.filters.push({ Name: key, Value: n });
                            }
                        });

                        if (this.gridApi) {
                            var grid = this.gridApi.grid;
                            angular.forEach(grid.columns, (n: uiGrid.IGridColumn) => {
                                if (n.filters[0].term) {
                                    searchObject.filters.push({ Name: n.name, Value: n.filters[0].term });
                                }

                                if (n.sort.direction) {
                                    searchObject.sorts.push({
                                        Name: n.name,
                                        SortDirection: n.sort.direction === "asc" ? 0 : 1,
                                        SortOrder: n.sort.priority
                                    });
                                }
                            });
                        }

                        var params = {
                            connectSearch: searchObject,
                            numberRecords: this.paginationOptions.pageSize,
                            pageNumber: this.paginationOptions.pageNumber,
                        }

                        return this.refundSettingService.getList().query(params, (result: Array<interfaces.master.IRefundSettingDisplay>) => {
                            this.gvwRefundSettings.data = result;
                            if (result[0]) {
                                this.gvwRefundSettings.totalItems = result[0].NumRecs;
                            } else {
                                this.gvwRefundSettings.totalItems = 0;
                            }
                        }, (errorResponse) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                        }).$promise;
                    });
            }

            public gvwRefundSettings: uiGrid.IGridOptions | any = {
                data: [],
                enableSorting: true,
                enableFiltering: true,
                useExternalFiltering: false,
                enableCellEdit: false,
                useExternalSorting: false,
                multiSelect: false,
                enableColumnResizing: true,
                enableRowSelection: false,
                enableFullRowSelection: false,
                enableRowHeaderSelection: false,
                enableHorizontalScrollbar: 2,
                enablePinning: false,
                rowEditWaitInterval: -1,
                paginationPageSizes: [10, 20, 50, 100],
                paginationPageSize: 10,
                enableVerticalScrollbar: 1,
                useExternalPagination: true,
                enableGridMenu: true,
                exporterMenuCsv: false,
                exporterMenuPdf: false,
                exporterMenuExcel: true,
                exporterExcelFilename: 'RefundSettingList.xlsx',
                gridMenuShowHideColumns: false,
                exporterFieldCallback: (grid, row, col, value) => {
                    return this.generalService.handleValue(value);
                },
                exporterSuppressColumns: ['EDIT', 'NUMRECS'],
                onRegisterApi: (gridApi) => { this.registerGridApi(gridApi) },                
                columnDefs: [{
                    name: "EDIT",
                    displayName: "",
                    enableFiltering: false,
                    cellTemplate: `
                            <div class="GridButton"> 
                                <button type="button" ng-click="grid.appScope.refundSettingListCtrl.editRefundSetting(row.entity.Id)" class="btn btn-default btn-sm">
                                    <span class="fa fa-pencil"></span>
                                </button>
                            </div>`,
                    enableSorting: false,
                    enableColumnMenu: false,
                    width: 35
                }, {
                    name: "EntityCode",
                    displayName: "Entity",
                    field: "EntityCode",
                    width: 150,
                    cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD}}</div>`,
                }, {
                    name: "DeclarationCountryCode",
                    displayName: "Declaration Country",
                     field: "DeclarationCountryCode",
                    width: 150,
                    cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD}}</div>`,
                }, {
                    name: "RefundType",
                    displayName: "Refund Type",
                    field: "RefundClaimCode",
                    width: 890,
                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{row.entity.RefundClaimCode}} - {{row.entity.RefundClaimDescription}}">{{row.entity.RefundClaimCode}} - {{row.entity.RefundClaimDescription}}</div>`,
                }, {
                    name: "IsActive",
                    displayName: "Is Active",
                    field: "IsActive",
                    width: 100,
                        cellTemplate: '<input type="checkbox" ng-disabled="true" ng-model="MODEL_COL_FIELD" />',
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="checkbox" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                            </div>`
                }]
            };

            public registerGridApi(gridApi: uiGrid.IGridApi) {
                this.gridApi = gridApi;

                this.gridApi.core.on.sortChanged(this.$scope, (grid, sortColumns) => {
                    this.DoSearch();

                    if (sortColumns[0]) {
                        this.sortName = sortColumns[0].name;
                        this.sortDirection = sortColumns[0].sort.direction;
                    }
                    else {
                        this.sortName = "";
                        this.sortDirection = "";
                    }
                });

                this.gridApi.pagination.on.paginationChanged(this.$scope, (newPage, pageSize) => { this.ListPageChange(newPage, pageSize) });

                this.gridApi.cellNav.on.navigate(this.$scope, (newCol) => {
                    this.$timeout(() => {
                        this.gridApi.selection.selectRow(newCol.row.entity);
                    });
                });
            }

            public editRefundSetting(id: number) {
                this.$state.go("auth.MasterData.RefundSetting.Update", { refId: id });
            }

            ListPageChange(newPage, pageSize) {
                var dirtyRows = _.map(this.gridApi.rowEdit.getDirtyRows(), (value) => { return value.entity; });
                if (this.allDirtyRows) {
                    _.forEach(dirtyRows, (value) => {
                        //If a dirty row exists in the new dirty rows then overwrite the old one.
                        var row: interfaces.master.IRefundSettingDisplay = _.find(this.allDirtyRows, _.matchesProperty('Id', value.Id));

                        if (row) {
                            //Row exists with the same Id.
                            var i: number = _.indexOf(this.allDirtyRows, row);
                            this.allDirtyRows[i] = row;
                        }
                        else {
                            this.allDirtyRows = _.concat(this.allDirtyRows, value);
                        }
                    });
                }
                else {
                    this.allDirtyRows = dirtyRows;
                }

                this.paginationOptions.pageNumber = newPage;
                this.paginationOptions.pageSize = pageSize;

                this.DoSearch();
            }

            loadEntities(searchText: string) {
                return this.entityService.getDropdownList(searchText).query(
                    () => {
                    }, (failureData) => {
                    }).$promise;
            }

            loadCountries() {
                var defered = this.$q.defer();

                this.countryService.getDropdownList().query({}, (data: ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>) => {
                    defered.resolve(data);
                }, () => {
                    defered.resolve([]);
                });


                return defered.promise;
            }

            loadRefundTypes() {
                return this.refundClaimTypeService.getDropdownList(this.refSettingSearch.DeclarationCountry.Id).query(() => { }, (failureData) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                }).$promise;
            }

            addNew() {
                this.$uibModal.open({
                    animation: true,
                    size: "lg",
                    template: `
                        <div class="modal-header">
                            <h3 class="modal-title" id="modal-title">Refund Settings - Add New Refund Setting</h3>
                        </div>
                        <div bs-loading-overlay bs-loading-overlay-reference-id="refund.add">
                            <form name="refundAdd">
                                <div class="gts-padding">
                                    <gts-message-display messages="Messages"></gts-message-display>
                                    <div class="modal-body" id="modal-body">
                                        <div class="row">
				                            <div class="row vertical-align">
					                            <div class="col-md-4">
                                                    <opt></opt>
                                                    <req></req>
						                            <label>Owner Entity</label>
					                            </div>
					                            <div class="col-md-6">
						                            <gts-dropdown name="ownerEntity" ng-model="addNewCtrl.selectedEntity" form="inputForm" load-data="addNewCtrl.loadEntities(searchText)" required="true"></gts-dropdown>
					                            </div>
				                            </div>
			                            </div>
                                        <div class="row">
				                            <div class="row vertical-align">
					                            <div class="col-md-4">
                                                    <opt></opt>
                                                    <req></req>
						                            <label>Declaration Country</label>
					                            </div>
					                            <div class="col-md-6">
						                            <gts-dropdown name="country" ng-model="addNewCtrl.selectedCountry" form="inputForm" load-data="addNewCtrl.loadCountries()" required="true" ng-change="addNewCtrl.onCountryChange()" ng-disabled="!addNewCtrl.selectedEntity"></gts-dropdown>
					                            </div>
				                            </div>
			                            </div>
                                        <div class="row">
				                            <div class="row vertical-align">
					                            <div class="col-md-4">
                                                    <opt></opt>
                                                    <req></req>
						                            <label>Refund Type</label>
					                            </div>
					                            <div class="col-md-6">
						                            <gts-dropdown name="country" ng-model="addNewCtrl.selectedRefundType" form="inputForm" load-data="addNewCtrl.loadRefundTypes()" required="true" ng-change="addNewCtrl.onCountryChange()" ng-disabled="!addNewCtrl.selectedCountry"></gts-dropdown>
					                            </div>
				                            </div>
			                            </div>
                                    
                                    </div>
                                    <div class="modal-footer">
                                        <button class="btn btn-primary" type="button"  ng-disabled="!addNewCtrl.selectedCountry && !addNewCtrl.selectedRefundType" ng-click="addNewCtrl.add()">Add New</button>
                                        <button class="btn btn-info" type="button" ng-click="addNewCtrl.cancel()">Cancel</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        `,
                    controller: class addNewCtrl {
                        selectedEntity: interfaces.applicationcore.IDropdownModel;
                        selectedCountry: interfaces.applicationcore.IDropdownModel;
                        selectedRefundType: interfaces.applicationcore.IDropdownModel;
                        description: string;
                        refundNumber: string;
                        listRestriction: Array<interfaces.applicationcore.IDropdownModel> = [];
                        messages: interfaces.applicationcore.IMessage[];

                        constructor($scope, private $uibModalInstance: angular.ui.bootstrap.IModalInstanceService,
                            private countryService: interfaces.applicationcore.ICountryService,
                            private entityService: interfaces.applicationcore.IEntityService,
                            private refundSettingService: interfaces.master.IRefundSettingService,
                            public generalService: interfaces.applicationcore.IGeneralService,
                            private $rootScope: interfaces.applicationcore.IRootScope,
                            private $state: ng.ui.IStateService,
                            private refundClaimTypeService: interfaces.tariff.IRefundClaimTypeService
                        ) {
                            this.selectedEntity = <interfaces.applicationcore.IDropdownModel>{
                                Id: $rootScope.Entity.Id,
                                Code: $rootScope.Entity.Code,
                                Description: $rootScope.Entity.Name,
                                Display: $rootScope.Entity.Code + " - " + $rootScope.Entity.Name,
                                Selected: true
                            };
                        }

                        cancel() {
                            this.$uibModalInstance.close(false);
                        }

                        add() {
                            var saveItem: interfaces.master.IRefundSetting = <interfaces.master.IRefundSetting>{
                                Id: 0,
                                Entity: this.selectedEntity,
                                DeclarationCountry: this.selectedCountry,
                                RefundClaim: this.selectedRefundType
                            };

                            this.refundSettingService.saveRefundSetting().save(saveItem, (result) => {
                                this.generalService.displayMessageHandler(result);
                                if (!result.HasErrorMessage) {
                                    this.$state.go("auth.MasterData.RefundSetting.Update", { refId: result.ID });
                                }

                                this.$uibModalInstance.close(true);
                            }, (errorResult) => {
                                this.generalService.displayMessageHandler(errorResult.data);

                                if (errorResult.data.Messages) {
                                    this.messages = [];
                                }

                                angular.forEach(errorResult.data.Messages, (o) => {
                                    angular.forEach(o.MessageItems, (n) => {
                                        this.messages.push(n);
                                    });
                                });
                            });
                        }

                        loadEntities(searchText: string) {
                            return this.entityService.getDropdownList(searchText).query(
                                () => {
                                }, (failureData) => {
                                }).$promise;
                        }

                        loadCountries() {
                            return this.countryService.getDropdownList().query({
                            }, () => {
                            }, (failureData) => {
                                this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                            }).$promise;
                        }

                        loadRefundTypes() {
                            return this.refundClaimTypeService.getDropdownList(this.selectedCountry.Id).query(() => { }, (failureData) => {
                                this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                            }).$promise;
                        }

                        onCountryChange() {
                           // this.selectedRestriction = null;
                           // this.listRestriction = [];
                        }


                    },
                    controllerAs: "addNewCtrl",
                    resolve: {
                    }
                }).result.then((result) => {
                    if (result) {
                        this.loadRefundSettings();
                    }
                });
            }

            printDocuments() {
                if (this.gridApi && this.gridApi.selection.getSelectedRows().length > 0) {
                    var selected = this.gridApi.selection.getSelectedRows()[0];
                    this.$state.go("auth.MasterData.RefundSetting.Update.Documents", { permId: selected.Id });
                } else {
                    this.generalService.displayMessage("Please select a refund", Enum.EnumMessageType.Warning);
                }
            }

            showAll_click() {
                this.refSettingSearch = {                  
                };

                this.DoSearch();
            }

            search_click() {
                this.DoSearch();
            }

            DoSearch() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'refundSetting'
                },
                    () => {
                        var searchObject: interfaces.applicationcore.ISearchObject = {
                            filters: [],
                            sorts: []
                        }
                        angular.forEach(this.refSettingSearch, (n, key) => {
                            if (n) {
                                if (n["Id"]) {
                                    searchObject.filters.push({ Name: key, Value: n.Id });
                                } else {
                                    if (n instanceof moment) {
                                        searchObject.filters.push({ Name: key, Value: n });
                                    } else {
                                        searchObject.filters.push({ Name: key, Value: n });
                                    }
                                }
                            }
                        });

                        if (this.gridApi) {
                            var grid = this.gridApi.grid;
                            angular.forEach(grid.columns, (n: uiGrid.IGridColumn) => {
                                if (n.filters[0].term) {
                                    searchObject.filters.push({ Name: n.name, Value: n.filters[0].term });
                                }

                                if (n.sort.direction) {
                                    searchObject.sorts.push({
                                        Name: n.name,
                                        SortDirection: n.sort.direction === "asc" ? 0 : 1,
                                        SortOrder: n.sort.priority
                                    });
                                }
                            });
                        }

                        var params = {
                            connectSearch: searchObject,
                            numberRecords: this.paginationOptions.pageSize,
                            pageNumber: this.paginationOptions.pageNumber,
                        }

                        ///////////////////////////////
                        return this.refundSettingService.getList().query(params, (result: Array<interfaces.master.IRefundSettingDisplay>) => {
                            //////////////////////////////
                            var currentDirtyRows: Array<interfaces.master.IRefundSettingDisplay> = [];

                            _.forEach(this.allDirtyRows, function (value) {
                                var row: interfaces.master.IRefundSettingDisplay = _.find(result, _.matchesProperty('Id', value.Id));

                                if (row) {
                                    var i: number = _.indexOf(result, row);
                                    result[i] = value;
                                    currentDirtyRows.push(result[i]);
                                }
                            });

                            this.gvwRefundSettings.data = result;

                            if (currentDirtyRows.length > 0) {
                                this.$timeout(() => {
                                    _.forEach(currentDirtyRows, (value) => {
                                        this.gridApi.rowEdit.setRowsDirty(currentDirtyRows);
                                    });
                                });
                            }

                            if (result[0]) {
                                this.gvwRefundSettings.totalItems = result[0].NumRecs;
                            } else {
                                this.gvwRefundSettings.totalItems = 0;
                            }
                        }, (errorResponse) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                        }).$promise;
                    });
            }
        }

        angular.module("app").controller("refundSettingListCtrl", controllers.master.refundSettingListCtrl);
    }
}